import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import AppTextField from "../../lib/components/AppTextField";
import { useNavigate } from "react-router-dom";
import { Button, Chip, InputAdornment, Pagination, Tab, Tabs } from "@mui/material";
import PreloaderTable from "../../lib/components/PreloaderTable";
import { Add, Search } from "@mui/icons-material";
import administratorsService from "../../service/administratorsService";
import { useMutation, useQuery } from "@tanstack/react-query";
import AppSelectField from "../../lib/components/AppSelectField";
import UsersDataTable from "./components/users-data-table";
import CreateUser from "./components/create-user";
import ConfirmationDialog from "../../lib/components/ConfirmationDialog";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import scrollHandler from "../../utils/scrollHandler";

const tabList = [
  { label: "All", value: "ALL" },
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "INACTIVE" },
];

const Administrators = () => {
  const navigate = useNavigate();
  const [isCreate, setIsCreate] = React.useState(false);
  const [isActivate, setIsActivate] = React.useState(false);
  const [isDeactivate, setIsDectivate] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);

  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "20";
  const keyword = searchParams.get("keyword") || "";
  const status = searchParams.get("status") || "";

  const qKeys = [page, limit, status];
  const queries = { page, limit, keyword, status };

  const usersQuery = useQuery({
    retry: false,
    queryKey: ["app-admins", ...qKeys],
    queryFn: () => administratorsService.get({ ...queries }),
  });

  const statsQuery = useQuery({
    retry: false,
    queryKey: ["admins-stats"],
    queryFn: administratorsService.getStats,
  });

  const activateMutation = useMutation({
    mutationFn: (data) => administratorsService.activate(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      toast.success("User account successfully activated.");
      usersQuery.refetch();
      statsQuery.refetch();
      setIsActivate(false);
    },
  });

  const deactivateMutation = useMutation({
    mutationFn: (data) => administratorsService.deactivate(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      toast.success("User account successfully deactivated.");
      usersQuery.refetch();
      statsQuery.refetch();
      setIsDectivate(false);
    },
  });

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      usersQuery.refetch();
    }, 1000);
    return () => clearTimeout(timeoutId);
    //eslint-disable-next-line
  }, [keyword]);

  const { isPending: isLoading, data } = usersQuery;
  const { data: stats } = statsQuery;

  const usersData = data?.data;
  const statsData = stats?.data?.data;
  return (
    <AppLayout>
      <Header title="Administrators" description="Manage who has access to what on Jureb" />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <div className="row mb-5">
          <div className="col-6 col-md-3 col-lg-4 mb-3">
            <div>
              <AppTextField
                placeholder="Enter Text Here..."
                value={keyword}
                onChange={(e) => handleChange(e, "keyword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search className="text-secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppSelectField
                value={status}
                placeholder="Status"
                onChange={(e) => handleChange(e, "status")}
                items={[
                  { label: "All", value: "ALL" },
                  { label: "Active", value: "ACTIVE" },
                  { label: "Inactive", value: "INACTIVE" },
                ]}
              />
            </div>
          </div>

          <div className="col-6 col-md-3 col-lg-6 mb-3">
            <div className="d-flex justify-content-end">
              <Button startIcon={<Add />} onClick={() => setIsCreate(true)} variant="contained" color="secondary">
                Add User
              </Button>
            </div>
          </div>
        </div>
        <div className="border-bottom mb-5">
          <Tabs value={status || "ALL"} onChange={handleChangeTab}>
            {tabList?.map((item, index) => {
              let value = 0;
              if (statsData) {
                value = statsData[item?.value.toLowerCase()];
              }
              return <Tab key={index} value={item?.value} label={<TabLabel title={item?.label} label={value} />} />;
            })}
          </Tabs>
        </div>
        <PreloaderTable isLoading={isLoading} />
        <UsersDataTable
          isLoading={isLoading}
          data={usersData?.data}
          onActivate={onActivate}
          onDeactivate={onDeactivate}
        />
        <div className="my-3">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div>
              <AppSelectField
                value={limit}
                placeholder="Limit"
                onChange={(e) => handleChange(e, "limit")}
                items={[
                  { label: "20", value: "20" },
                  { label: "30", value: "30" },
                  { label: "40", value: "40" },
                  { label: "50", value: "50" },
                ]}
              />
            </div>
            <div>
              <Pagination
                page={Number(page)}
                count={usersData?.totalPages}
                onChange={(_, value) => {
                  handleChange(value, "page");
                }}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isActivate}
        type="confirm"
        title="Activate User"
        isLoading={activateMutation.isPending}
        onClose={() => setIsActivate(false)}
        onSubmit={activateUser}
        body={`You have selected to activate this user <span class="fw-medium">"${selectedUser?.firstName} ${selectedUser?.lastName}</span>". Select "<span class="fw-medium">Yes, activate</span>" to continue.`}
        actionTitle="Yes, activate"
      />
      <ConfirmationDialog
        isOpen={isDeactivate}
        title="Deactivate User"
        isLoading={deactivateMutation.isPending}
        onClose={() => setIsDectivate(false)}
        onSubmit={deactivateUser}
        body={`You have selected to deactivate this user <span class="fw-medium">"${selectedUser?.firstName} ${selectedUser?.lastName}</span>". Select "<span class="fw-medium">Yes, deactivate</span>" to continue.`}
        actionTitle="Yes, deactivate"
      />
      <CreateUser isOpen={isCreate} onClose={() => setIsCreate(false)} refetch={usersQuery.refetch} />
    </AppLayout>
  );

  function handleChangeTab(_, value) {
    searchParams.set("status", value);
    searchParams.set("page", "1");
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  }

  function handleChange(e, name = "") {
    const value = name === "page" ? e : e.target.value;
    searchParams.set(name, value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
      { scrollY: 0 }
    );
    if (name === "page") scrollHandler();
  }

  function activateUser() {
    activateMutation.mutate(selectedUser?.id);
  }
  function deactivateUser() {
    deactivateMutation.mutate(selectedUser?.id);
  }

  function onActivate(data) {
    setIsActivate(true);
    setSelectedUser(data);
  }
  function onDeactivate(data) {
    setIsDectivate(true);
    setSelectedUser(data);
  }
};

function TabLabel({ title, label }) {
  return (
    <div className="d-flex gap-3 align-items-center">
      <span>{title}</span>
      <Chip label={label} />
    </div>
  );
}

export default Administrators;
