import { Button, IconButton, Paper, TextField } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import JurebLogo from "../../lib/assets/images/jureb-logo.png";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SIGNIN_VALIDATION } from "../../validations/auth";
import { useMutation } from "@tanstack/react-query";
import authservice from "../../service/authservice";
import BTNLoader from "../../lib/components/BTNLoader";
import errorHandler from "../../utils/errorHandler";
import pathnames from "../../constants/pathnames";
import saveToken from "../../utils/saveToken";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const defaultValues = {
  email: "",
  password: "",
};

const SignIn = () => {
  const [visibility, setVisibility] = React.useState({
    password: false,
  });
  const formHook = useForm({
    resolver: yupResolver(SIGNIN_VALIDATION),
    mode: "all",
    defaultValues: defaultValues,
  });

  const errors = formHook.formState.errors;
  const control = formHook.control;
  const handleSubmit = formHook.handleSubmit;

  const mutation = useMutation({
    mutationFn: (data) => authservice.signin(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      saveToken(data?.data);
      window.location.replace(pathnames.DASHBOARD);
    },
  });

  async function save(data) {
    mutation.mutate(data);
  }

  const { isPending } = mutation;
  return (
    <div style={{ minHeight: "100vh" }} className="d-flex align-items-center bg-light">
      <div className="container-xxl">
        <form action="" onSubmit={handleSubmit(save)}>
          <Paper className="border mx-auto my-5" style={{ maxWidth: 600 }}>
            <div className="p-3 mx-auto my-5" style={{ maxWidth: 400 }}>
              <div className="d-flex justify-content-center mb-3">
                <img src={JurebLogo} alt="Jureb" />
              </div>
              <div className="text-center fw-medium fs-4 mb-4">Welcome Back</div>
              <div className="mb-3">
                <label htmlFor="" className="">
                  Email Address<span className="text-danger">*</span>
                </label>

                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="email"
                      fullWidth
                      variant="outlined"
                      placeholder="Enter email address"
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      helperText={errors["email"]?.message}
                      error={!!errors["email"]}
                    />
                  )}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="">
                  Password<span className="text-danger">*</span>
                </label>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Enter password"
                      value={field.value}
                      type={visibility.password ? "text" : "password"}
                      onChange={(e) => field.onChange(e.target.value)}
                      helperText={errors["password"]?.message}
                      error={!!errors["password"]}
                      InputProps={{
                        endAdornment: (
                          <IconButton size="small" onClick={() => handleChangeVisibility("password")}>
                            {visibility.password ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                />
              </div>
              <div className="mb-5 text-end">
                <Link to={pathnames.FORGOT_PASSWORD} className="text-primary">
                  Forgot Password?
                </Link>
              </div>
              <div className="mb-3 mx-auto" style={{ maxWidth: 300 }}>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isPending}
                  variant="contained"
                  color="secondary"
                  startIcon={<BTNLoader isLoading={isPending} />}
                >
                  Sign In
                </Button>
              </div>
            </div>
          </Paper>
        </form>
      </div>
    </div>
  );

  function handleChangeVisibility(name) {
    setVisibility((prev) => ({ ...prev, [name]: !prev[name] }));
  }
};

export default SignIn;
