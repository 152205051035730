import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import AppTextField from "../../lib/components/AppTextField";
import { useNavigate } from "react-router-dom";
import { InputAdornment, Pagination } from "@mui/material";
import PreloaderTable from "../../lib/components/PreloaderTable";
import { Search } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import AppSelectField from "../../lib/components/AppSelectField";
import UsersDataTable from "./components/users-data-table";
import scrollHandler from "../../utils/scrollHandler";
import userservice from "../../service/userservice";
import useDebounce from "../../hooks/useDebounce";
import AppTab from "../../components/tab-label";

const tabList = [{ label: "All", value: "ALL" }];

const Users = () => {
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "20";
  const keyword = searchParams.get("keyword") || "";
  const status = searchParams.get("status") || "";
  const searchTerm = useDebounce(keyword);

  const queries = { page, limit, keyword: searchTerm, status };

  const usersQuery = useQuery({
    retry: false,
    queryKey: ["subscribers", ...Object.values(queries)],
    queryFn: () => userservice.find({ ...queries }),
  });

  const { isPending: isLoading, data } = usersQuery;

  const usersData = data?.data;
  return (
    <AppLayout>
      <Header title="Users" description="Manage who has access to what on Jureb" />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <div className="row mb-5">
          <div className="col-6 col-md-3 col-lg-4 mb-3">
            <div>
              <AppTextField
                placeholder="Enter Text Here..."
                value={keyword}
                onChange={(e) => handleChange(e, "keyword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search className="text-secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <AppTab
          items={tabList}
          onChange={handleChangeTab}
          value={status || "ALL"}
          statsMap={{ all: usersData?.totalDocs }}
        />
        <PreloaderTable isLoading={isLoading} />
        <UsersDataTable isLoading={isLoading} data={usersData?.data} />
        <div className="my-3">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div>
              <AppSelectField
                value={limit}
                placeholder="Limit"
                onChange={(e) => handleChange(e, "limit")}
                items={[
                  { label: "20", value: "20" },
                  { label: "30", value: "30" },
                  { label: "40", value: "40" },
                  { label: "50", value: "50" },
                ]}
              />
            </div>
            <div>
              <Pagination
                page={Number(page)}
                count={usersData?.totalPages}
                onChange={(_, value) => {
                  handleChange(value, "page");
                }}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );

  function handleChangeTab(_, value) {
    searchParams.set("plan", value);
    searchParams.set("page", "1");
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  }

  function handleChange(e, name = "") {
    const value = name === "page" ? e : e.target.value;
    searchParams.set(name, value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
      { scrollY: 0 }
    );
    if (name === "page") scrollHandler();
  }
};

export default Users;
