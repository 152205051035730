import moment from "moment";
import httpservice from "./httpservice";

class PushNotificationService {
  get(query = {}) {
    const params = {};
    if (query?.keyword) params.keyword = query.keyword;
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    if (query?.dateFrom) params.dateFrom = query.dateFrom;
    if (query?.dateTo) params.dateTo = query.dateTo;
    if (query?.status && query?.status !== "ALL") params.status = query.status;
    return httpservice.get("/admin/cp/push-notification/fetch", { params });
  }
  getStats(query = {}) {
    const params = {};
    if (query?.keyword) params.keyword = query.keyword;
    if (query?.dateFrom) params.dateFrom = query.dateFrom;
    if (query?.dateTo) params.dateTo = query.dateTo;
    if (query?.status && query?.status !== "ALL") params.status = query.status;
    return httpservice.get("/admin/cp/push-notification/category-count", { params });
  }
  create(data) {
    const formData = new FormData();
    const date = moment(data?.date).format("YYYY-MM-DD");
    const time = moment(data?.time).format("HH:mm:ss");
    formData.append("title", data.title);
    formData.append("image", data.image);
    formData.append("text", data.text);
    formData.append("message", data.message);
    formData.append("dateTime", moment(`${date} ${time}`).toDate());
    data.deliveryTypes.forEach((type) => {
      formData.append("deliveryTypes[]", type);
    });
    data.recipients.forEach((recipient) => {
      formData.append("recipients[]", recipient);
    });
    data.subscriptionPlans.forEach((plan) => {
      formData.append("subscriptionPlans[]", plan);
    });
    return httpservice.post("/admin/cp/push-notification/create", formData);
  }
  delete(id) {
    return httpservice.delete("/admin/cp/push-notification/delete", {
      params: { id },
    });
  }
}

export default new PushNotificationService();
