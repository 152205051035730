import httpservice from "./httpservice";

class PaymentService {
  get(query = {}) {
    const params = { plan: "", period: "" };
    if (query?.keyword) params.keyword = query.keyword;
    if (query?.page) params.page = query.page;
    if (query?.id) params.organizationId = query.id;
    if (query.plan !== "ALL") params.plan = query.plan;
    if (query?.limit) params.limit = query.limit;
    if (query?.dateFrom) params.dateFrom = query.dateFrom;
    if (query?.dateTo) params.dateTo = query.dateTo;
    if (query?.period !== "ALL") params.period = query.period;
    return httpservice.get("/admin/cp/invoices", { params });
  }
  getInvoicesStats(query = {}) {
    const params = { plan: "", period: "" };
    if (query?.keyword) params.keyword = query.keyword;
    if (query?.id) params.organizationId = query.id;
    if (query.plan !== "ALL") params.plan = query.plan;
    if (query?.dateFrom) params.dateFrom = query.dateFrom;
    if (query?.dateTo) params.dateTo = query.dateTo;
    if (query?.period !== "ALL") params.period = query.period;
    return httpservice.get("/admin/cp/invoices/sub-period-stats", { params });
  }
}

export default new PaymentService();
