import httpservice from "./httpservice";

class OrganizationService {
  get(query = {}) {
    const params = { plan: "" };
    if (query?.keyword) params.keyword = query.keyword;
    if (query?.page) params.page = query.page;
    if (query.plan !== "ALL") params.plan = query.plan;
    if (query?.limit) params.limit = query.limit;
    if (query?.dateFrom) params.dateFrom = query.dateFrom;
    if (query?.dateTo) params.dateTo = query.dateTo;
    if (query?.state) params.state = query.state;
    if (query?.status && query?.status !== "ALL") params.status = query.status;
    if (query?.subscriptionStatus && query?.subscriptionStatus !== "ALL")
      params.subscriptionStatus = query.subscriptionStatus;
    if (query?.isFreeTrial && query?.isFreeTrial !== "ALL") params.isFreeTrial = query.isFreeTrial;
    // if (query?.state) {
    //   return httpservice.get("/admin/cp/organizations/by-location", { params });
    // }
    return httpservice.get("/admin/cp/organizations/", { params });
  }
  getOne(query = {}) {
    const params = {};
    if (query?.id) params.id = query.id;
    return httpservice.get("/admin/cp/organizations/single", { params });
  }
  subscriptionPeriodStats(query = {}) {
    const params = { plan: "" };
    if (query?.keyword) params.keyword = query.keyword;
    if (query.plan !== "ALL") params.plan = query.plan;
    if (query?.dateFrom) params.dateFrom = query.dateFrom;
    if (query?.dateTo) params.dateTo = query.dateTo;
    if (query?.state) params.state = query.state;
    if (query?.status && query?.status !== "ALL") params.status = query.status;
    if (query?.subscriptionStatus && query?.subscriptionStatus !== "ALL")
      params.subscriptionStatus = query.subscriptionStatus;
    if (query?.isFreeTrial && query?.isFreeTrial !== "ALL") params.isFreeTrial = query.isFreeTrial;
    return httpservice.get("/admin/cp/organizations/sub-period-stats", { params });
  }
  getInvoice(query = {}) {
    const params = {};
    if (query?.id) params.id = query.id;
    return httpservice.get("/admin/cp/invoices/single", { params });
  }
  getInvoices(query = {}) {
    const params = { plan: "", period: "" };
    if (query?.page) params.page = query.page;
    if (query?.id) params.organizationId = query.id;
    if (query.plan !== "ALL") params.plan = query.plan;
    if (query?.limit) params.limit = query.limit;
    if (query?.dateFrom) params.dateFrom = query.dateFrom;
    if (query?.dateTo) params.dateTo = query.dateTo;
    if (query?.period !== "ALL") params.period = query.period;
    return httpservice.get("/admin/cp/organizations/invoices", { params });
  }
  getSubscriptionPlans() {
    return httpservice.get("/admin/cp/meta/plans");
  }
  cancelSubscription(data) {
    return httpservice.put("/admin/cp/organizations/subscription/cancel", data);
  }
  upgradeSubscription(data) {
    return httpservice.post("/admin/cp/organizations/subscription/upgrade", data);
  }
  getAdministrators(query = {}) {
    const params = {};
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    if (query?.status && query?.status !== "ALL") params.status = query.status;
    if (query?.organizationId) params.organizationId = query.organizationId;
    return httpservice.get("/admin/cp/organizations/administrators", {
      params,
    });
  }
  getActivities(query = {}) {
    const params = {};
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    return httpservice.get("/admin/cp/activities/organization", {
      params,
    });
  }
  getUser(query = {}) {
    const params = {};
    if (query?.id) params.id = query.id;
    return httpservice.get("/admin/cp/users/single", { params });
  }
  getUsers() {
    return httpservice.get("");
  }
}

export default new OrganizationService();
