import { Chip, Tab, Tabs } from "@mui/material";

export default function AppTab({ onChange, value, items = [], statsMap = null }) {
  return (
    <div className={`border-bottom mb-5`}>
      <Tabs value={value} onChange={onChange}>
        {items?.map((item, index) => {
          let value = 0;
          if (statsMap) {
            value = statsMap[item?.value.toLowerCase()];
          }

          return <Tab key={index} value={item?.value} label={<TabLabel title={item?.label} label={value} />} />;
        })}
      </Tabs>
    </div>
  );
}

function TabLabel({ title, label }) {
  return (
    <div className="d-flex gap-3 align-items-center">
      <span>{title}</span>
      <Chip label={label} />
    </div>
  );
}
