import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Chip, IconButton, InputAdornment, Pagination, Tab, Tabs } from "@mui/material";
import { ArrowBack, ContentCopy, QrCode } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { moneyFormat } from "../../utils/moneyFormat";
import PersonsIcon from "../../lib/assets/icons/referral__icon-3.svg";
import MoneyIcon from "../../lib/assets/icons/referral__icon-2.svg";
import MoneyIconBlue from "../../lib/assets/icons/referral__icon-5.svg";
import MoneyCashIcon from "../../lib/assets/icons/referral__icon-1.svg";
import moment from "moment";
import scrollHandler from "../../utils/scrollHandler";
import PreloaderTable from "../../lib/components/PreloaderTable";
import AppTextField from "../../lib/components/AppTextField";
import AppSelectField from "../../lib/components/AppSelectField";
import { useQuery } from "@tanstack/react-query";
import referralsService from "../../service/referralsService";
import ReferralsDataTable from "./components/referrals-data-table";
import ProcessPayout from "../payouts/components/process-payout";
import { toast } from "react-toastify";
import ReferrerDiscountSettings from "./components/referrer-discount-settings";
import ReferrerCodes from "./components/referrer-codes";

const tabList = [
  { label: "All", value: "ALL" },
  { label: "Simple Start", value: "SIMPLE_START" },
  { label: "Standard", value: "STANDARD" },
  { label: "Premium", value: "PREMIUM" },
];

const menuProps = {
  commission: false,
  payout: false,
  discount: false,
  menu: null,
  paymentIssue: false,
  changeCode: false,
};

const ReferrerDetails = () => {
  const { id } = useParams();
  const currency = "NGN";
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "20";
  const status = searchParams.get("status") || "";
  const keyword = searchParams.get("keyword") || "";
  const plan = searchParams.get("plan") || "";
  const [isSettings, setIsSettings] = React.useState(menuProps);
  const [isCopied, setIsCopied] = React.useState({
    link: false,
    code: false,
  });

  const qKeys = [page, limit, status, id];
  const queries = { page, limit, keyword, status, referrerId: id };

  const referrerQuery = useQuery({
    retry: false,
    queryKey: ["referrer", id],
    queryFn: () => referralsService.getReferrer({ id }),
  });

  const referralsQuery = useQuery({
    retry: false,
    queryKey: ["referrals", ...qKeys],
    queryFn: () => referralsService.getReferrals({ ...queries }),
  });
  const referralsStatsQuery = useQuery({
    retry: false,
    queryKey: ["referralsStats", ...qKeys],
    queryFn: () => referralsService.getReferralsStats({ ...queries }),
  });

  const settingQuery = useQuery({
    retry: false,
    queryKey: ["payout-setting"],
    queryFn: () => referralsService.getPayoutSetting(),
  });

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      referralsQuery.refetch();
      referralsStatsQuery.refetch();
    }, 1000);
    return () => clearTimeout(timeoutId);
    //eslint-disable-next-line
  }, [keyword]);

  const referralsData = referralsQuery?.data?.data;
  const referralsStats = referralsStatsQuery?.data?.data?.data;
  const referrer = referrerQuery?.data?.data?.data;
  const settingsData = settingQuery?.data?.data?.data?.payout;
  return (
    <AppLayout>
      <Header title="Referrer" description="Get insights to users on Jureb's referral program here" />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <IconButton className="bg-secondary-subtle mb-3" onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <div className="d-flex flex-wrap align-items-end  mb-5">
          <div className="bg-white rounded border" style={{ maxWidth: 480 }}>
            <div className="p-3">
              <div className="d-flex gap-4 mb-2">
                <div className="flex-grow-1 fw-medium fs-5">
                  {referrer?.accountMeta?.firstName} {referrer?.accountMeta?.lastName}
                </div>
              </div>
              <div className="mb-2 fs-sm">
                <span className="text-secondary me-2">Email:</span>
                <span className="fw-medium">{referrer?.accountMeta?.email}</span>
              </div>
              <div className="mb-2 fs-sm">
                <span className="text-secondary me-2">Phone Number:</span>
                <span className="fw-medium">
                  {referrer?.accountMeta?.phone?.startsWith("+") ? (
                    <span>{referrer?.accountMeta?.phone}</span>
                  ) : (
                    <span className={referrer?.accountMeta?.phone ? "" : "d-none"}>
                      +{referrer?.accountMeta?.phone}
                    </span>
                  )}
                </span>
              </div>
              <div className="mb-2 fs-sm">
                <span className="text-secondary me-2">Invited Businesses:</span>
                <span className="fw-medium">{referrer?.count?.referral || 0}</span>
              </div>
              <div className="mb-2 fs-sm">
                <span className="text-secondary me-2">Paid Invitees:</span>
                <span className="fw-medium">{referrer?.count?.paid || 0}</span>
              </div>

              <div className="mb-2 d-flex w-100 align-items-center">
                <div className="flex-grow-1">
                  <div className="d-flex align-items-center">
                    <div className="text-secondary me-2 text-nowrap fs-sm ">Referral Link:</div>
                    <div className="">
                      <a
                        rel="noreferrer"
                        target="_blank"
                        id="referral-link"
                        className="fw-medium fs-sm text-truncate-1 text-decoration-underline"
                        href={`https://app.jureb.com?ref=${referrer?.code}`}
                      >
                        https://app.jureb.com?ref={referrer?.code}
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    size="small"
                    endIcon={<ContentCopy />}
                    onClick={() => copyToClipboard("referral-link", "link")}
                  >
                    {isCopied.link ? "Copied" : "Copy"}
                  </Button>
                </div>
              </div>
              <div className="mb-2 d-flex align-items-center">
                <div className="fs-sm flex-grow-1">
                  <span className="text-secondary me-2 ">Referral Code:</span>
                  <span className="fw-medium">
                    <span id="referral-code">{referrer?.code}</span>
                  </span>
                </div>
                <Button size="small" endIcon={<ContentCopy />} onClick={() => copyToClipboard("referral-code", "code")}>
                  {isCopied.code ? "Copied" : "Copy"}
                </Button>
              </div>
            </div>
          </div>
          <div className="flex-grow-1 gap-4 d-flex justify-content-end">
            <div>
              <Button
                variant="outlined"
                color="secondary"
                disabled={!referrer}
                onClick={() => handleMenu("discount", true)}
              >
                Subscribers Discount
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                disabled={!referrer}
                onClick={() => handleMenu("paymentIssue", true)}
              >
                Process Payout
              </Button>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <StatsCard title={"Total Paid Subscriptions"} amount={referrer?.count?.paid || 0} Icon={PersonsIcon} />
          <StatsCard
            title={"Total Commissions Earned"}
            amount={moneyFormat(referrer?.total?.earned, currency)}
            Icon={MoneyIcon}
          />
          <StatsCard
            title={"Total Commissions Paid"}
            amount={moneyFormat(referrer?.total?.paid, currency)}
            Icon={MoneyCashIcon}
          />
          <StatsCard
            title={"Available Balance"}
            amount={moneyFormat(referrer?.total?.earned - referrer?.total?.paid, currency)}
            Icon={MoneyIconBlue}
          />
        </div>

        <div className="row mb-3">
          <div className="col-6 col-md-3 col-lg-4 mb-3">
            <div>
              <AppTextField
                placeholder="Enter Text Here..."
                value={keyword}
                onChange={(e) => handleChange(e, "keyword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon className="text-secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppSelectField
                value={status}
                placeholder="Status"
                onChange={(e) => handleChange(e, "status")}
                items={[
                  { label: "All", value: "ALL" },
                  { label: "Active", value: "ACTIVE" },
                  { label: "Inactive", value: "INACTIVE" },
                  { label: "Expired", value: "EXPIRED" },
                ]}
              />
            </div>
          </div>
        </div>
        <div className={`border-bottom mb-5`}>
          <div className="d-flex gap-4 align-items-center justify-content-between">
            <Tabs value={plan || "ALL"} onChange={handleChangeTab}>
              {tabList?.map((item, index) => {
                let value = 0;
                if (referralsStats) {
                  if (index === 0) value = referralsData?.totalDocs;
                  else value = referralsStats[item?.value];
                }
                return <Tab key={index} value={item?.value} label={<TabLabel title={item?.label} label={value} />} />;
              })}
            </Tabs>
            <Button startIcon={<QrCode />} onClick={() => handleMenu("changeCode", true)} color="secondary">
              See Referrer Codes
            </Button>
          </div>
        </div>
        <PreloaderTable isLoading={referralsQuery.isLoading} />
        <ReferralsDataTable isLoading={referralsQuery.isLoading} data={referralsData?.data} />
        <div className="my-3">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div>
              <AppSelectField
                value={limit}
                placeholder="Limit"
                onChange={(e) => handleChange(e, "limit")}
                items={[
                  { label: "20", value: "20" },
                  { label: "30", value: "30" },
                  { label: "40", value: "40" },
                  { label: "50", value: "50" },
                ]}
              />
            </div>
            <div>
              <Pagination
                page={Number(page)}
                count={referralsData?.totalPages}
                onChange={(_, value) => {
                  handleChange(value, "page");
                }}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
      <ProcessPayout
        referrers={[referrer]}
        isOpen={isSettings.paymentIssue}
        onClose={() => handleMenu("paymentIssue", false)}
      />
      <ReferrerDiscountSettings
        id={id}
        data={referrer?.signupDiscount}
        isOpen={isSettings.discount}
        refetch={() => referrerQuery.refetch()}
        onClose={() => handleMenu("discount", false)}
      />
      <ReferrerCodes
        referrer={referrer}
        codes={referrer?.codes || []}
        isOpen={isSettings.changeCode}
        refetch={() => referrerQuery.refetch()}
        onClose={() => handleMenu("changeCode", false)}
      />
    </AppLayout>
  );

  function handleMenu(name, value) {
    if (name === "commission" || name === "payout") {
      setIsSettings((p) => ({ ...p, menu: false, [name]: value }));
      return;
    }
    if (name === "paymentIssue" && value === true) {
      const total = referrer?.total?.earned - referrer?.total?.paid;
      if (total < Number(settingsData?.threshholdAmount || 1)) {
        toast.error("Referrer is not eligible for payout :(");
        return;
      }
      if (!referrer?.bank) {
        toast.error("Referrer bank details is required for payout :(");
        return;
      }
    }
    setIsSettings((p) => ({ ...p, [name]: value }));
  }

  function handleChange(e, name = "") {
    const value = name.includes("date") ? moment(e).format("YYYY-MM-DD") : name === "page" ? e : e.target.value;
    searchParams.set(name, value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
    if (name === "page") scrollHandler();
  }

  function handleChangeTab(_, value) {
    searchParams.set("plan", value);
    searchParams.set("page", "1");
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  }

  function copyToClipboard(id, type) {
    try {
      const text = document.getElementById(id).innerHTML;
      navigator.clipboard.writeText(text);

      setIsCopied((prev) => ({ ...prev, [type]: true }));
      setTimeout(() => {
        setIsCopied((prev) => ({ ...prev, [type]: false }));
      }, 1500);
    } catch (error) {
      console.error("CLIPBOARD BODY:", error);
    }
  }
};

function StatsCard({ title, amount, Icon }) {
  return (
    <div className="col-12 col-md-3 mb-3">
      <div className="border bg-white rounded p-3 h-100 d-flex flex-column justify-content-between">
        <div className="d-flex gap-3 align-items-center mb-3">
          <div className="flex-grow-1 fs-sm">{title}</div>
          <div>
            <img src={Icon} alt={title} width={50} height={50} />
          </div>
        </div>
        <div className="fs-4 fw-medium">{amount}</div>
      </div>
    </div>
  );
}

function TabLabel({ title, label }) {
  return (
    <div className="d-flex gap-3 align-items-center">
      <span>{title}</span>
      <Chip label={label} />
    </div>
  );
}

export default ReferrerDetails;
